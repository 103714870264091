import { FormatDateOptions } from "@flicket/utils";
import { omit } from "@styled-system/props";
import {
  Box,
  Flex,
  PrimaryButton,
  Stack,
  SystemProps,
  TertiaryButton,
  Text,
  LegacyText,
  TransparentButton,
} from "flicket-ui";
import { useRouter } from "next/router";
import { SyntheticEvent, useEffect } from "react";
import { Icon, Row as StyledRow } from "~components";
import { EventQuery } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import { formatEventDateRange } from "~lib/helpers/formatDate";
import {
  AttributeNames,
  InteractionNames,
} from "~telemetry/enums/AttributeNames";
import useCaptureClick from "~telemetry/traces/useCaptureClick";
import { formatEventDate } from "../../lib/helpers/formatDate";

type RowProps = Partial<EventQuery["event"]> &
  SystemProps & {
    isMembership?: boolean;
    locations: string[];
  };

export const Row = ({
  id,
  title,
  hasPublicRelease,
  isMembership,
  locations,
  ...props
}: RowProps) => {
  const router = useRouter();
  const { isSinglePage, hasFeature } = useOrganization();

  const captureClick = useCaptureClick({
    forwardToNest: true,
    eventId: !isMembership && id,
    pageName: "event-list-page",
  });

  const navigateEvent = async () =>
    isMembership
      ? router.push("/memberships/[membershipId]", `/memberships/${id}`)
      : router.push("/events/[eventId]", `/events/${id}`);

  const navigateReservation = async (e: SyntheticEvent) => {
    e.stopPropagation();

    return isMembership
      ? router.push(
          "/memberships/[membershipId]/reservation",
          `/memberships/${id}/reservation`
        )
      : router.push(
          "/events/[eventId]/reservation",
          `/events/${id}/reservation`
        );
  };

  useEffect(() => {
    void router.prefetch("/events/[eventId]", `/events/${id}`);
    void router.prefetch(
      "/events/[eventId]/reservation",
      `/events/${id}/reservation`
    );
    void router.prefetch(
      "/memberships/[eventId]/reservation",
      `/memberships/${id}/reservation`
    );
    void router.prefetch("/memberships/[eventId]", `/memberships/${id}`);
  }, []);

  const dateOptions: FormatDateOptions = {
    showStartTime: !hasFeature("hideEventTime"),
    timeZone: props?.venue?.timezone,
    locale: props?.venue?.locale,
  };

  return (
    <StyledRow
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={{ _: 0, md: 3 }}
      px={{ _: 0, md: 2 }}
      pt={{ _: "6/4", md: 2 }}
      pb={{ _: "6/4", md: 2 }}
      onClick={navigateEvent}
      {...omit(props)}
      key={id}
    >
      <Stack gap={2} alignItems="center" flex="1" minW={0}>
        <Stack w="64px" direction="vertical" gap={0} alignItems="center">
          <Text variant="header.M">
            {formatEventDate(props.startDate, "custom", {
              day: "numeric",
              ...dateOptions,
            })}
          </Text>
          <Text variant="regular" textTransform="uppercase">
            {formatEventDate(props.startDate, "custom", {
              month: "short",
              ...dateOptions,
            })}
          </Text>
        </Stack>
        <Flex
          flex="1"
          flexDir="column"
          maxW={{ _: "calc(100% - 96px)", md: "auto" }}
          minW={0}
          mr={3}
        >
          <Text as={"h1"} mb="1/2" maxW="100%" variant="header.S">
            {title}
          </Text>
          <Text variant="regular">
            {formatEventDateRange(props.startDate, props.endDate, {
              format: "basicShort",
              ...dateOptions,
            })}
          </Text>
          <Text as={"h2"} variant="regular">
            {locations.join(", ")}
          </Text>
        </Flex>
      </Stack>
      <Box width="24px" d={{ 0: "flex", md: "none" }}>
        <TransparentButton>
          <Icon fontSize={6} icon="chevron-right" color="P300" />
        </TransparentButton>
      </Box>
      <Box height="36px" d={{ 0: "none", md: "flex" }}>
        <TertiaryButton px={3} py="1/2">
          {isSinglePage ? (
            <LegacyText fontSize={2} lineHeight={"16px" as any}>
              Ticket Information
            </LegacyText>
          ) : (
            <LegacyText fontSize={2} lineHeight={"16px" as any}>
              Event Information
            </LegacyText>
          )}
        </TertiaryButton>
        {hasPublicRelease && (
          <PrimaryButton
            px={3}
            py="1/2"
            ml="6/4"
            onClick={captureClick(
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              navigateReservation,
              InteractionNames.BUTTON_PRIMARY,
              {
                [AttributeNames.BUTTON_LABEL]: "Buy Tickets",
              }
            )}
          >
            <LegacyText fontSize={2} lineHeight={"16px" as any}>
              Buy Tickets
            </LegacyText>
          </PrimaryButton>
        )}
      </Box>
    </StyledRow>
  );
};
